import { isServer } from 'flex-gateway-ssr/utils'

const i18nCreateInstance = async () => {
  return (
    isServer
      ? import('@flexiness/languages/dist/i18n-serverside.mjs').then(module => {
        const { i18nextServerSide } = module.default || module
        return i18nextServerSide
      })
      : import('@flexiness/languages/dist/i18n-clientside.mjs').then(async(module) => {
        const { i18nextClientSide } = module.default || module
        return i18nextClientSide
      })
  )
}

export { i18nCreateInstance }

/* eslint-disable @typescript-eslint/no-empty-function */

// import { createContext } from 'react'
// import { Car } from '../types/APIResponsesTypes'

// const SelectedCarContext = createContext<[(Car | null), (selectedCar: Car | null) => void]>
//   ([
//     {
//       id: 1337,
//       name: 'Fido',
//       animal: 'dog',
//       description: 'Lorem ipsum',
//       breed: 'Beagle',
//       images: [],
//       city: 'Seattle',
//       state: 'WA',
//     },
//     () => {},
//   ])

// export default SelectedCarContext

import { createContext } from 'react'

const SelectedCarContext = createContext()

export default SelectedCarContext

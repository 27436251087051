if (process.browser) {
  Object.assign(__webpack_share_scopes__.default, {
    "next/link": {
      [next.version]: {
        loaded: true,
        get: () => Promise.resolve(() => require("next/link")),
      },
    },
    "next/router": {
      [next.version]: {
        loaded: true,
        get: () => Promise.resolve(() => require("next/router")),
      },
    },
    "next/head": {
      [next.version]: {
        loaded: true,
        get: () => Promise.resolve(() => require("next/head")),
      },
    },
    "next/dynamic": {
      [next.version]: {
        loaded: true,
        get: () => Promise.resolve(() => require("next/dynamic")),
      },
    },
  });
  
  if(next.version.startsWith("12")) {
    Object.assign(__webpack_share_scopes__.default, {
     "next/script": {
        [next.version]: {
          loaded: true,
          get: () => Promise.resolve(() => require("next/script")),
        },
      },
    });
  }
}
if (process.browser) {
window.flex_design_system_react_ts_modfed?.init(__webpack_share_scopes__.default);
};/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
'use client'; // this directive should be at top of the file, before any imports.
import React from 'react';
import { promises as fs } from 'fs';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import getConfig from 'next/config';
const { serverRuntimeConfig } = getConfig();
import { useRouter as useNextRouter } from 'next/router';
import Headroom from 'headroom.js';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import SelectedCarContext from 'carjager/context/SelectedCarContext';
// import classNames from 'classnames'
const { fetchFlexCSPNonce } = await import(
/* webpackMode: "lazy-once" */
/* eager: true */
/* webpackPreload: true */
'@flexiness/domain-utils');
import { observer, enableStaticRendering } from 'mobx-react-lite';
import log from 'loglevel';
log.setDefaultLevel('info');
// Helpers
import { isServer, i18nCreateInstance } from 'flex-gateway-ssr/utils';
import { getStores, StoreProvider } from 'flex-gateway-ssr/stores';
// import type I18next from 'i18next'
import { useTranslation } from 'react-i18next';
const _i18nInstance = await i18nCreateInstance();
const _nonce = isServer
    ? JSON.parse(`${await fs.readFile(`${serverRuntimeConfig.PROJECT_ROOT}/nonce.json`)}`)['nonce']
    : await fetchFlexCSPNonce(`${process.env.FLEX_GATEWAY_HOST}`);
// eslint-disable-next-line camelcase
__webpack_nonce__ = _nonce;
const Layout = dynamic(() => import('../components/layout'));
// import Error from './_error'
// const Logo = dynamic(() => import('../components/logo'))
// const showLogo = () => {
//   return (
//     <div className={`flex-gateway-layout`}>
//       <div className={`component`}>
//         <div className='logoHolder'>
//           <Logo />
//         </div>
//       </div>
//     </div>
//   )
// }
import 'flex-tailwind/styles/globals.css';
import '../styles/globals/flex-gateway-globals.css';
import '../styles/default/App.page.css';
import '../styles/default/Navigation.component.css';
// import '../styles/default/SlickSliderReact.component.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css';
enableStaticRendering(isServer);
const stores = getStores();
let flexRouter = globalThis?.Flexiness?.domainApp?.router || null;
const loadFlex = async () => {
    import('@flexiness/domain-lib-mobx-react-router').then((factory) => {
        const { getRouter } = factory;
        flexRouter = getRouter();
    });
};
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            cacheTime: Infinity,
        },
    },
});
// https://github.com/vercel/next.js/issues/8784#issuecomment-1258007356
const MyApp = ({ Component, pageProps }) => {
    // const MyApp = ({ Component, pageProps }: AppPropsWithLayout) => {
    // const {
    //   // error,
    //   // _initLng,
    //   // _i18n,
    //   stores,
    // } = pageProps
    // const [queryClient] = React.useState(() => new QueryClient({
    //   defaultOptions: {
    //     queries: {
    //       staleTime: Infinity,
    //       cacheTime: Infinity,
    //     },
    //   },
    // }))
    const { appContext, flexI18next, setFlexI18next, 
    // setStatus,
    navigationState } = stores.UIStore;
    const selectedCar = React.useState(null);
    // console.log('///////////////////////////////////////////////////////////////////')
    // console.log('_app.render isServer', isServer)
    // console.log('_app.render pageProps', pageProps)
    // console.log('///////////////////////////////////////////////////////////////////')
    const { 
    // t,
    i18n } = useTranslation();
    React.useEffect(() => {
        if (!i18n)
            return;
        setFlexI18next(i18n);
    }, [i18n, !flexI18next]);
    React.useEffect(() => {
        if (navigationState === 'top') {
            const headroom = new Headroom(document.querySelector('#__next .flex-gateway-layout.top .navigation'));
            // initialise
            headroom.init();
        }
    }, [navigationState]);
    if (!isServer)
        loadFlex();
    const [routingCompleted, setRoutingCompleted] = React.useState(false);
    const nextRouter = useNextRouter();
    // ///////////// Keep NextRouter in Sync with FlexRouter (History) /////////////
    if (!isServer && routingCompleted && flexRouter) {
        try {
            const { location } = flexRouter;
            const currentBasename = globalThis?.Flexiness?.domainApp?.MFStore?.basename;
            log.info(`MFStore | App basename is ${currentBasename}`);
            log.info(`FlexRouter | App has changed to ${location?.pathname}`);
            log.info(`pageName | App is ${pageProps.pageName}`);
            // match anything after the first / until we encounter another /.
            // https://stackoverflow.com/questions/5735483/regex-to-get-first-word-after-slash-in-url
            const _locationBasename = location?.pathname.replace(/^\/([^/]*).*$/, '$1');
            log.info(`_locationBasename | App is ${_locationBasename}`);
            if (`/${_locationBasename}` !== currentBasename) {
                if (`/${_locationBasename}` === location.pathname) {
                    nextRouter.push(location?.pathname);
                }
                if (`/${_locationBasename}` !== location.pathname) {
                    import('@flexiness/domain-lib-mobx-react-router').then((factory) => {
                        const { getMFStore } = factory;
                        const MFStore = getMFStore();
                        MFStore.setBasename(`/${_locationBasename}`);
                    });
                    nextRouter.replace(location?.pathname);
                }
            }
        }
        catch (e) {
            log.warn('[gateway/_app] listener | flexRouter | history - location', e);
        }
    }
    React.useEffect(() => {
        const handleRouteWillChange = (url, { shallow }) => {
            log.info(`NextRouter | App is changing to ${url} ${shallow ? 'with' : 'without'} shallow routing`);
            setRoutingCompleted(false);
        };
        const handleRouteHasChanged = (url, { shallow }) => {
            log.info(`NextRouter | App has changed to ${url} ${shallow ? 'with' : 'without'} shallow routing`);
            setRoutingCompleted(true);
        };
        nextRouter.events.on('routeChangeStart', handleRouteWillChange);
        nextRouter.events.on('routeChangeComplete', handleRouteHasChanged);
        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            nextRouter.events.off('routeChangeStart', handleRouteWillChange);
            nextRouter.events.off('routeChangeComplete', handleRouteHasChanged);
        };
    }, [nextRouter]);
    // // https://stackoverflow.com/questions/47586505/next-js-return-the-404-error-page-in-getinitialprops
    // if (error) {
    //   return (<Error statusCode={error} />) // customize your error page
    // }
    const appContextHead = (appContext) => {
        return (React.createElement(Head, null,
            React.createElement("meta", { nonce: _nonce, property: 'csp-nonce', content: `${_nonce}` }),
            React.createElement("script", { nonce: _nonce, dangerouslySetInnerHTML: {
                    __html: `window.__webpack_nonce__="${_nonce}"`
                } }),
            appContext.theme.palette.mode === 'light'
                ? (React.createElement("style", { type: 'text/css', "data-gateway-theme": `${appContext.theme.palette.mode}`, nonce: _nonce }, `
                :root {
                  --flex-link: rgba(23, 43, 77, 1);
                  --flex-link-hover: rgba(23, 43, 77, 0.65);
                  --flex-link-inverse: #fff;
                  --flex-link-hover-inverse: rgba(101, 141, 167, 1);
                  --flex-gateway-text: #32325d;
                  --flex-gateway-header: #32325d;
                  --flex-gateway-stroke-grey: rgba(69, 69, 69, 1);
                  --flex-gateway-bg: #f6f3ea;
                  --flex-gateway-highlight: #f9423a;
                  --flex-gateway-navbar-color: #fff;
                  --flex-gateway-navbar-bg: #172b4d;
                  --flex-danger: #f9423a;
                  --flex-section-fill-light: #f0f2f4;
                }
              `)) : (React.createElement("style", { type: 'text/css', "data-gateway-theme": `${appContext.theme.palette.mode}`, nonce: _nonce }, `
                :root {
                  --flex-link: #fff;
                  --flex-link-hover: rgba(23, 43, 77, 0.65);
                  --flex-link-inverse: rgba(23, 43, 77, 1);
                  --flex-link-hover-inverse: rgba(23, 43, 77, 0.65);
                  --flex-gateway-text: #f6f3ea;
                  --flex-gateway-header: #fff;
                  --flex-gateway-stroke-grey: rgba(250, 250, 250, 1);
                  --flex-gateway-bg: #32325d;
                  --flex-gateway-highlight: #f9423a;
                  --flex-gateway-navbar-color: #172b4d;
                  --flex-gateway-navbar-bg: #fff;
                  --flex-danger: #f9423a;
                  --flex-section-fill-light: #f0f2f4;
                }
              `))));
    };
    // Use the layout defined at the page level, if available
    // const getNestedLayout = Component.getLayout ?? ((page: ReactElement) => page)
    return (React.createElement(React.Fragment, null,
        appContextHead(appContext),
        React.createElement(StoreProvider, { value: stores },
            React.createElement(SelectedCarContext.Provider, { value: selectedCar },
                React.createElement(QueryClientProvider, { client: queryClient },
                    React.createElement(Hydrate, { state: pageProps.dehydratedState },
                        React.createElement(Layout, { props: pageProps },
                            React.createElement(Component, { ...pageProps }))))))));
};
// https://github.com/vercel/next.js/discussions/36832
// MyApp.getInitialProps = async (context: AppContext) => {
//   // log.info('_app getInitialProps context:', context)
//   const {
//     Component,
//     // router,
//     ctx
//   } = context
//   const {
//     req,
//     res,
//     pathname,
//     query,
//     asPath
//   } = ctx
//   let pageProps = {}
//   if (Component.getInitialProps) {
//     try {
//       pageProps = await Component.getInitialProps(ctx)
//     } catch (error) {
//       pageProps = { error }
//     }
//   }
//   // https://nextjs.org/docs/advanced-features/custom-app
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(context)
//   pageProps = { ...pageProps, ...appProps }
//   // https://github.com/borekb/nextjs-with-mobx
//   // On server-side, this runs once and creates new stores
//   // On client-side, this always reuses existing stores
//   // const mobxStores = getStores();
//   // // Make stores available to page's `getInitialProps`
//   // context.ctx.mobxStores = mobxStores;
//   return {
//     pageProps: {
//       ...pageProps,
//     }
//   }
// }
export default observer(MyApp);

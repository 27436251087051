/* eslint-disable no-console */
import {
  toJS,
  makeAutoObservable,
  observable,
  action,
  // computed
} from 'mobx'

import {
  AppContextInterface,
  NavigationState,
  demoPageFile,
  StatusFrontend,
  FlexGlobalThis,
  PrefetchInterface,
  FlexI18next,
  CarJagerInterface
} from 'flexiness'

import { Car } from '../carjager/types/APIResponsesTypes'
// import { Car } from 'carjager/types/APIResponsesTypes'

// import { defaultLocale } from '@flexiness/languages/dist/i18n-constants.js'
// import { defaultLocale } from 'flex-languages/i18n-constants'

const uniqueArray = (array: Car[]): Car[] => {
  return array.filter(
    (item, index, self) =>
      index === self.findIndex((x) => x.id === item.id)
  )
}

declare let globalThis: FlexGlobalThis
// declare type getUIStore = () => UserInterfaceStore
export class UserInterfaceStore {
  constructor(
    public appContext: AppContextInterface,
    public navigationState: NavigationState,
    public demoPage: demoPageFile,
    public status: StatusFrontend,
    public prefetchData: PrefetchInterface,
    public flexI18next: FlexI18next,
    public carjager: CarJagerInterface
  ) {
    makeAutoObservable(this, {
      appContext: observable,
      setAppContext: action,
      navigationState: observable,
      setNavigationState: action,
      demoPage: observable,
      setDemoPage: action,
      setStatus: action,
      setPrefetchData: action,
      flexI18next: observable,
      setFlexI18next: action,
      carjager: observable,
      setCarjager: action,
      setSelectedCarByID: action,
      getCarByID: action
    })
    this.appContext = appContext
    this.navigationState = navigationState
    this.status = status
    this.prefetchData = prefetchData
    this.flexI18next = flexI18next
    this.carjager = carjager
  }

  setAppContext = (newAppContext: AppContextInterface) => {
    this.appContext = newAppContext
  }

  setNavigationState = (newState: NavigationState) => {
    this.navigationState = newState
  }

  setDemoPage = (newPageFile: demoPageFile) => {
    this.demoPage = newPageFile
  }

  setStatus = (newStatus: StatusFrontend) => {
    this.status = newStatus
  }

  setPrefetchData = (newPrefetchedData: PrefetchInterface) => {
    this.prefetchData = newPrefetchedData
  }

  setFlexI18next = (newFlexI18next: FlexI18next) => {
    this.flexI18next = newFlexI18next
    console.log('///////////////////////////////////////////////////////////////////')
    console.log('UIStore resolvedLanguage', this.flexI18next?.resolvedLanguage)
    console.log('///////////////////////////////////////////////////////////////////')
    this.setAppContext(
      {
        ...this.appContext,
        language: this.flexI18next?.resolvedLanguage
      }
    )
  }

  setCarjager = (newCarJagerData: CarJagerInterface) => {
    this.carjager = newCarJagerData
  }

  setCarjagerPage = (newCarJagerPage: number) => {
    this.setCarjager(
      {
        ...this.carjager,
        page: newCarJagerPage
      }
    )
  }

  setCarjagerPageData = (newCarJagerPageData: Car[]) => {
    this.setCarjager(
      {
        ...this.carjager,
        // cars: this.carjager.page > 1
        //   ? [
        //     ...this.carjager.cars,
        //     ...newCarJagerPageData
        //   ]
        //   : [
        //     ...newCarJagerPageData
        //   ]
        cars: uniqueArray(
          [
            ...this.carjager.cars,
            ...newCarJagerPageData
          ]
        )
      }
    )
    console.log(toJS(this.carjager.cars))
  }

  getCarByID = (id: string) => {
    if (!this.carjager.cars.length) return false
    return this.carjager.cars.filter(car => `${car.id}` === id)[0] as Car
  }

  setSelectedCarByID = (id: string) => {
    const [_selectedCar] = this.carjager.cars.filter(car => `${car.id}` === id)
    this.setCarjager(
      {
        ...this.carjager,
        selectedCar: _selectedCar
      }
    )
    console.log(toJS(this.carjager.selectedCar))
  }
}

let UIStore: UserInterfaceStore | undefined = globalThis?.Flexiness?.domainApp?.UIStore
export function getUIStore() {
  if (!UIStore) {
    UIStore = new UserInterfaceStore(
      // appContext
      {
        theme: {
          palette: {
            mode: 'light'
          }
        },
        // Rest when setFlexI18next is called
        // language: defaultLocale,
        language: 'en',
        debug: false,
        cssMode: 'CSS Modules',
        overlayMode: false
      },

      // navigationState
      'fullpage',

      // demoPage
      'default',

      // status
      null,

      // prefetch
      {} as PrefetchInterface,

      // i18n
      {} as FlexI18next,

      // carjager
      {
        page: 1,
        cars: [],
        selectedCar: null
      } as CarJagerInterface,
    )
    globalThis.Flexiness = {
      // @ts-expect-error
      ...globalThis?.Flexiness,
      domainApp: { ...globalThis?.Flexiness?.domainApp, UIStore }
    }
  }
  return UIStore
}

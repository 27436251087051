import React from 'react'
import { isServer } from 'flex-gateway-ssr/utils'
// import { getUIStore } from './UIStore'
import { getUIStore, UserInterfaceStore } from 'flex-gateway-ssr/stores/UIStore'

interface ClientSideStores {
  UIStore: UserInterfaceStore
}

let clientSideStores: ClientSideStores

// https://github.com/vercel/next.js/tree/master/examples/with-mobx
// https://www.themikelewis.com/post/nextjs-with-mobx
// https://github.com/borekb/nextjs-with-mobx

export function getStores() {
  if (isServer) {
    return {
      UIStore: getUIStore(),
    }
  }
  if (!clientSideStores) {
    clientSideStores = {
      UIStore: getUIStore(),

      // ...(!isServer ?
      //   {
      //     // Requires Dom for BrowserHistory
      //     MFStore: import('@flexiness/domain-lib-mobx-react-router')
      //       .then((factory) => {
      //         const { getMFStore } = factory
      //         const MFStore = getMFStore()
      //         debugger
      //         return Promise.resolve(MFStore)
      //       })
      //   } : null
      // ),

      // ...(!isServer ?
      //   {
      //     // Requires Dom for BrowserHistory
      //     MFStore: new Promise(resolve => {
      //       import('@flexiness/domain-lib-mobx-react-router')
      //         .then((factory) => {
      //           const { getMFStore } = factory
      //           const MFStore = getMFStore()
      //           debugger
      //           return resolve(MFStore)
      //         })
      //       })
      //   } : null
      // ),
    }
  }

  return clientSideStores
}

// type ContextProps = {
//   children: React.ReactNode;
//   stores: ClientSideStores;
// };

// type ProviderValue = ClientSideStores
// type DefaultValue = undefined
// type ContextValue = DefaultValue | ProviderValue;
// const StoreContext = React.createContext<ContextValue>(undefined)

// export const StoreProvider = (props: ContextProps) => {
//   return <StoreContext.Provider value={props.stores}>{props.children}</StoreContext.Provider>
// }

const defaultValue = null
const StoreContext = React.createContext(defaultValue)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StoreProvider = (props: any) => {
  return <StoreContext.Provider value={props.value}>{props.children}</StoreContext.Provider>
}

export function useMobxStores() {
  return React.useContext(StoreContext)
}
